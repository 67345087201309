import React from 'react'
import { BsTwitter, BsInstagram } from 'react-icons/bs'
import { FaLinkedin } from 'react-icons/fa'
const SocialMedia = () => {
    return (
        <div className="app__social">
            <div>
                <FaLinkedin onClick={() => window.open('https://www.linkedin.com/in/karim-fawaz/', '_blank')} />
            </div>
            <div>
                <BsInstagram onClick={() => window.open('https://instagram.com/karimfawaz_', '_blank')} />
            </div>
            <div>
                <BsTwitter onClick={() => window.open('https://twitter.com/karim_fawaz_', '_blank')} />
            </div>
        </div>
    )
}

export default SocialMedia