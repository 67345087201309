import './Highlights.scss'
import { AppWrap, MotionWrap } from '../../wrapper';



const Highlights = () => {
    return (
        <>
            <div className='app__highlights app__flex'>
                <h2 className="head-text">Podcast with <span>Kyle Mercer</span></h2>

                <iframe
                    src="https://www.youtube.com/embed/ToMi3Dj7xq4"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen />
            </div>
        </>

    )
}


export default AppWrap(
    MotionWrap(Highlights, 'app__highlights'),
    'highlights',
    'app__whitebg',
);