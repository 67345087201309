import React from 'react';

import { motion } from 'framer-motion';

import { images } from '../../constants';

import './Header.scss';
import { AppWrap } from '../../wrapper';


const Header = () => {
  return (
    <div className="app__header" >

      <motion.img animate={{ rotate: [-20, 20, -20], scale: [0, 1, .9, 1, 0] }} transition={{ repeat: Infinity, duration: 3, delay: 1.5 }} className='app__header-icon' alt='header-icon' src={images.coding} />
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className='app__header-center'>

        <h1 className='head-text'>Hey, I'm <span>Karim</span></h1>
        <br />
        <img className='app__header-img' src={images.memoji} />

      </motion.div>
      <motion.img animate={{ rotate: [-20, 20, -20], scale: [0, 1, .9, 1, 0] }} transition={{ repeat: Infinity, duration: 3 }} className='app__header-icon' alt='header-icon' src={images.design} />
      <motion.img animate={{ rotate: [-20, 20, -20], scale: [0, 1, .9, 1, 0] }} transition={{ repeat: Infinity, duration: 3 }} className='app__header-icon' alt='header-icon' src={images.ecommerce} />
      <motion.img animate={{ rotate: [-20, 20, -20], scale: [0, 1, .9, 1, 0] }} transition={{ repeat: Infinity, duration: 3, delay: 1.5 }} className='app__header-icon' alt='header-icon' src={images.mobile} />
    </div>
  )
}

export default AppWrap(Header, 'home', 'app__whitebg');
